.projects {
    padding: 7rem;
    background-color: #eee;
}

.projects .title {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    justify-content: center;
    align-items: center;
}

.projects .title h2 {
    color: #000 !important;
    font-weight: bold !;
}

.projects .title p {
    font-size: 1.2rem;
}

.projects .grille {
    display: grid;
    grid-template-columns: repeat(2, 49.25%);
    justify-content: space-between;
    margin-top: 2rem;
}

.projects .grille .card {
    padding: 1rem;
    border-radius: 10px;
    border: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1.25rem;
    cursor: pointer;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}

.projects .grille .card .overPlay {
    position: absolute;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.2);
    border-radius: 10px;
}

.projects .grille .card img {
    width: 100%;
}

.projects .grille .card .desc {
    position: absolute;
    background-color: #0999fd;
    width: 100%;
    bottom: 0;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    padding: 1rem;
    color: #fff;
    display: none;
}

.projects .grille .card .desc.visible {
    display: block;
}

.projects .grille .card .desc h6 {
    font-size: 1.2rem;
    color: #fff !important;
}

.projects .buttonContent {
    text-align: center;
    margin-top: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.projects .buttonContent button {
    background-color: transparent;
    padding: 1rem;
    border: 2px solid #0999fd;
    border-radius: 30px;
    display: flex;
    align-items: center;
    gap: .5rem;
}

.projects .buttonContent button span {
    color: #0999fd;
    font-weight: bold;
    font-size: 1.1rem;
}

.projects .buttonContent button svg {
    color: #0999fd;
    font-weight: bold;
    font-size: 1.1rem;
}

@media screen and (max-width:1200px) {
    .projects {
        padding: 7rem 2rem;
    }

    .projects .grille {
        grid-template-columns: repeat(2, 48.75%);
    }
}

@media screen and (max-width:900px) {
   .projects .grille .card .desc.visible {
        width: 100%;
        height: 100%;
        border-radius: 10px !important;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
    }
}

@media screen and (max-width:700px) {
    .projects .grille .card .desc h6,
    .projects .grille .card .desc p {
        display: none;
    }
}

@media screen and (max-width:600px) {
    .projects {
        padding: 2rem 1rem;
    }

    .projects .grille {
        grid-template-columns: repeat(2, 48%);
    }

    .projects .grille .card {
        margin-bottom: 1rem;
        padding: .5rem;
    }
}