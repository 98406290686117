.accueil {
    background-color: #fff;
    width: 100%;
    background: url("../../assets/images/ia.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 500px;
    position: relative;
}

.accueil .overPlay {
    position: absolute;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.3);
}

.accueil .text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
}

.accueil p {
    font-size: 1.3rem;
    font-weight: bold;
    color: #fff !important;
    text-align: center;
}

.accueil h1 {
    color: #fff !important;
    text-align: center;
    margin-bottom: 2rem;
}

.accueil .card {
    width: 500px;
    height: 500px;
    border-radius: 1000px;
    margin-top: 5rem;
}

.accueil img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 1000px;
}

@media screen and (max-width:900px) {
    .accueil .text{
        padding: 0 5rem;
    }
}

@media screen and (max-width:500px) {
    .accueil .text{
        padding: 0 2rem;
    }
}