.cv {
    background-color: #0999fd;
    background: url("../../assets/images/ia.jpg");
    height: 400px;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    background-attachment: fixed;
}

.cv .overPlay {
    position: absolute;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.3);
}

.cv a {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #0999fd;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: .5rem;
    padding: 1rem 2rem;
    z-index: 100;
    border-radius: 50px;
}

.cv a span {
    font-size: 24px;
    color: #fff;
}

.cv a svg {
    color: #fff;
    font-size: 25px;
}

.cv a:hover {
    border: 2px solid #0999fd;
}

@media screen and (max-width:630px) {
    .cv a {
        width: 70%;
    }
    .cv {
        height: 200px;
    }
}

@media screen and (max-width:450px) {
    .cv a {
        width: 90%;
    }

    .cv a span {
        font-size: 20px;
        color: #fff;
    }

    .cv a svg {
        color: #fff;
        font-size: 21px;
    }
}

@media screen and (max-width:300px) {
    .cv a {
        width: 98%;
    }

    .cv a span {
        font-size: 14px;
        color: #fff;
    }

    .cv a svg {
        color: #fff;
        font-size: 14px;
    }
}