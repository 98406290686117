.about {
    background-color: #0999fd;
    text-align: center;
    color: #fff;
    padding: 7rem;
}

.about .text {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    width: 80%;
    margin: auto;
}

.about h2 {
    color: #fff !important;
    font-weight: bold !;
}

.about .desc {
    font-size: 1.2rem;
    line-height: 30px;
    text-align: justify;
}

.about .cards {
    display: grid;
    grid-template-columns: repeat(4, 25%);
    margin-top: 4rem;
    justify-content: center;
}

.about .cards .card {
    border-radius: 0;
    min-height: 500px;
    border: 0;
    border-right: 1px solid #eee;
    padding: 2rem;
    display: flex;
    border: 0;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.about .cards .card:nth-child(1) {
    border-bottom-left-radius: 20px;
    border-top-left-radius: 20px;
}

.about .cards .card:nth-child(4) {
    border-bottom-right-radius: 20px;
    border-top-right-radius: 20px;
}

.about .cards .card .image {
    height: 70px;
    height: 70px;
}

.about .cards .card .image img {
    width: 70px;
    height: 70px;
    border: 1px solid rgb(37, 25, 25);
    border-radius: 140px;
    object-fit: cover;
}

.about .cards .card h5 {
    color: #0999fd !important;
    font-size: 1.1rem;
}

.about .cards .card .ul1 {
    list-style: lower-roman;
    display: flex;
    flex-direction: column;
    gap: .5rem;
}

.about .cards .card .ul1 li {
    color: #333;
    font-weight: 500;
    width: 100%;
}

.about .cards .card .ul2 {
    display: flex;
    flex-direction: column;
    list-style: none;
    gap: 1rem;
    color: #333;
    font-weight: 500;
}

@media screen and (max-width:1300px) {
    .about {
        padding: 7rem 2rem;
    }
}

@media screen and (max-width:1200px) {
    .about .cards {
        grid-template-columns: repeat(2, 50%);
    }

    .about .cards .card:nth-child(1) {
        border-bottom-left-radius: 0;
    }

    .about .cards .card:nth-child(2) {
        border-bottom-right-radius: 0;
        border-top-right-radius: 20px;
    }

    .about .cards .card:nth-child(3) {
        border-bottom-left-radius: 20px;
    }

    .about .cards .card:nth-child(4) {
        border-top-right-radius: 0;
    }

    .about .cards .card .ul1 {
        flex-direction: row;
        list-style: none;
        gap: 1rem;
        width: 100%;
    }
}

@media screen and (max-width:800px) {
    .about .cards .card .ul1 {
        list-style: lower-roman;
        display: flex;
        flex-direction: column;
        gap: .5rem;
        width: auto;
    }
}

@media screen and (max-width:600px) {
    .about {
        padding: 2rem 1rem;
    }

    .about .cards {
        grid-template-columns: repeat(1, 100%);
    }

    .about .cards .card {
        border-radius: 20px !important;
        margin-bottom: 1rem;
    }

    .about .text {
        gap: 1rem;
        width: 100%;
    }
    
    .about h2 {
        color: #fff !important;
    }
    
    .about .desc {
        font-size: 1rem;
        line-height: normal;
        text-align: left
    }
}