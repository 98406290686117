.contact {
    width: 100%;
    padding: 7rem;
    display: flex;
}

.contact .card {
    width: 50%;
    border: 0;
    border-radius: 0;
    height: 650px;
}

.contact .card:first-child {
    background-color: #ddd;
    background: url("../../assets/images/accueil.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    border-bottom-left-radius: 20px;
    border-top-left-radius: 20px;
    background-color: #ddd;
}

.contact .overPlay {
    position: absolute;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.6);
    border-bottom-left-radius: 20px;
    border-top-left-radius: 20px;
}


.contact .card:last-child {
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
}

.contact .card img {
    height: 650px;
    object-fit: cover;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
}

.contact .card h2 {
    color: #fff !important;
    z-index: 100;
}

.contact .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 2rem;
    height: 100%;
    padding: 2rem;
}

.contact .content .item {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.contact .content .itemChild {
    display: flex;
    flex-direction: column;
}

.contact .content svg,
.contact .content span {
    color: #fff;
    font-size: 15px;
    z-index: 100;
}

.contact .content .svg {
    border: 1px solid #0999fd;
    height: 50px;
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #0999fd;
    border-radius: 100px;
}

.contact .content svg {
    color: #fff;
    font-size: 20px !important;

}

.contact .content .title {
    font-weight: 800;
}

@media screen and (max-width:800px) {
    .contact {
        padding: 7rem 2rem;
    }
}

@media screen and (max-width:630px) {
    .contact {
        padding: 7rem 1rem;
    }
}

@media screen and (max-width:590px) {
    .contact {
        padding: 2rem 1rem;
        flex-direction: column;
    }

    .contact .card {
        width: 100%;
        margin-bottom: 1rem;
    }
}