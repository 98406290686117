@import url('https://fonts.googleapis.com/css2?family=Manrope&family=Michroma&family=Open+Sans:wght@400;700&family=Poppins:wght@300;400;500&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;1,400&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-size: 14px;
  font-family: "Open Sans";
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: bold !important;
  color: #000 !important;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

ul li{
  font-family: "Open Sans";
}