.footer {
    width: 100%;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    padding: 2rem 7rem;
    align-items: center;
    justify-content: center;
}

.footer .image {
    width: 50px;
    height: 50px;
    border-radius: 100px;
    background-color: #0999fd;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.footer .image h1 {
    color: #fff !important;
}

.footer .content1 {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    justify-content: center;
    align-items: center;
}

.footer .content1 p {
    text-align: center;
    font-size: 1.2rem;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 600;
}

.footer .content2 {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
}

.footer .content2 h6 {
    margin: 0;
    padding: 0;
}

@media screen and (max-width:520px) {
    .footer {
        padding: 2rem 1rem;
    }

    .footer .content2{
        flex-direction: column;
    }
}