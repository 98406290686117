.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #fff;
    width: 100%;
    padding: 1rem;
    padding-left: 7rem;
    padding-right: 7rem;
    position: sticky;
    top: 0;
    z-index: 1000000;
}

.header .image {
    width: 50px;
    height: 50px;
    border-radius: 100px;
    background-color: #0999fd;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.header .image h1{
    color: #fff !important;
    margin: 0;
    padding: 0;
}

.header ul {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    padding: 0;
    margin: 0;
    list-style: none;
}

.header ul a {
    text-decoration: none;
    color: #333;
    font-size: 15px;
    font-weight: 700;
}

@media screen and (max-width:600px) {
    .header{
        padding-left: 2rem;
        padding-right: 2rem;
    }
}

@media screen and (max-width:340px) {
    .header{
        padding-left: 1rem;
        padding-right: 1rem;
    }
}

@media screen and (max-width:290px) {
    .header{
        flex-direction: column;
        gap: 1rem;
    }
}